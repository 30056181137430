import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Spacer, Typography } from "@aviary";
import { AppleSSOButton, GoogleSSOButton } from "@shared/components";
import { isEmailValid } from "@shared/components/EmailInput";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { isPasswordValid, PasswordInput } from "@unauthenticated/shared/components/PasswordInput";
import { RecaptchaErrorBox } from "@unauthenticated/shared/components/RecaptchaErrorBox";
import { useGlobalConfig } from "@unauthenticated/shared/context";
import { l } from "@unauthenticated/shared/locales/i18n";

import { FormWrapper } from "../../FormWrapper";
import { PractitionerSignUpEmailInput } from "../../PractitionerSignUpEmailInput";
import { SignUpCarouselOrFooter } from "../../SignUpCarouselOrFooter";
import * as styles from "../SimplifySignUp.styles";
import type { SimplifySignUpWithSignUpPageProps } from "../types";

const PageTwo = ({
  formData,
  errors,
  recaptchaError,
  setFormData,
  logField,
  onSignInRedirect,
  onSubmit,
  additionalAttributes,
  pageVersion,
  signUpPage,
  isLoading,
  isAccountExists,
  setIsAccountExists,
}: SimplifySignUpWithSignUpPageProps) => {
  const { t } = useTranslation();

  const { emailRegex } = useGlobalConfig();
  const { isEmerson } = useSharedGlobalConfig();
  const { tablet } = useBreakpoints();

  const [emailDirty, setEmailDirty] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));

    // This component will fire the onChange event to display any existing
    // formData value in which case we don't want to delete the error
    if (formData[name] !== value && errors?.[name]) {
      delete errors[name];
    }
  };

  const setEmail = (email: string) => {
    setFormData(prevState => ({ ...prevState, email }));
  };

  const onEmailBlur = e => {
    if (isEmailValid(formData.email, emailRegex)) {
      logField("email");
    }
    setEmailDirty(true);
    if (e?.target.value) {
      handleChange(e);
    }
  };

  const onPasswordBlur = () => {
    if (isPasswordValid(formData.password)) {
      logField("password");
    }
  };

  const isFormValid = () => {
    const emailValid = isEmailValid(formData.email, emailRegex);
    const passwordValid = isPasswordValid(formData.password);

    return passwordValid && emailValid;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isFormValid()) {
      return;
    }

    if (isEmerson) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "signup",
        gaEventCategory: "emerson-ecologics-v2",
        gaEventAction: "main-form-signup",
      });
    }

    onSubmit();
  };

  const heading = isEmerson
    ? t(l.practitionerSignUp.Emerson.HowToCompleteYourAccount, { firstName: formData.firstName })
    : t(l.practitionerSignUp.HowToCompleteYourAccount, { firstName: formData.firstName });

  const subtitle = isEmerson
    ? t(l.practitionerSignUp.Emerson.SelectSignUpMethod)
    : t(l.practitionerSignUp.SelectSignUpMethod);

  const renderOAuth = () =>
    !isEmerson && (
      <div css={styles.fullWidth}>
        <GoogleSSOButton
          pracTypeId={formData?.practitionerType?.id}
          pageVersion={pageVersion}
          additionalAttributes={additionalAttributes}
          signUpPage={signUpPage}
          formData={{
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            storeName: formData?.dispensaryName,
            tosAcceptedAt: formData?.tosTimestamp,
          }}
        />
        <AppleSSOButton
          pracTypeId={formData?.practitionerType?.id}
          pageVersion={pageVersion}
          additionalAttributes={additionalAttributes}
          isOrTextRendered
          buttonStyles={styles.appleButton}
          signUpPage={signUpPage}
          formData={{
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            storeName: formData?.dispensaryName,
            tosAcceptedAt: formData?.tosTimestamp,
          }}
        />
      </div>
    );

  return (
    <form
      role="form"
      onSubmit={handleSubmit}
      css={styles.form}
      noValidate
      data-e2e="practitioner-sign-up-form"
      data-gtm-id="prac-sign-up-form"
    >
      <div css={styles.contentBox}>
        <Typography type="h2">{heading}</Typography>
        <Typography css={styles.subTitle}>{subtitle}</Typography>
        <FormWrapper>
          {renderOAuth()}
          {recaptchaError && <RecaptchaErrorBox />}
          <PractitionerSignUpEmailInput
            value={formData.email}
            onChange={handleChange}
            setEmail={setEmail}
            isDirty={emailDirty}
            required
            isAccountExists={isAccountExists}
            setIsAccountExists={setIsAccountExists}
            onSignInRedirect={onSignInRedirect}
            onBlur={onEmailBlur}
            wrapperStyles={styles.input}
          />
          <PasswordInput
            value={formData.password}
            onChange={handleChange}
            isLoading={false}
            required
            isDirty={passwordDirty}
            onFocus={() => setPasswordDirty(true)}
            onBlur={onPasswordBlur}
            wrapperStyles={styles.input}
            errors={errors?.password}
          />
          <Button
            type="submit"
            isFullWidth
            data-e2e="create-practitioner-button"
            disabled={isLoading || !isFormValid()}
            isLoading={isLoading}
          >
            {t(l.common.CreateAccount)}
          </Button>
        </FormWrapper>
        {tablet.greaterThan && (
          <>
            <Spacer height="quad" />
            <SignUpCarouselOrFooter isWithinViewport={tablet.greaterThan} />
          </>
        )}
      </div>
    </form>
  );
};

export { PageTwo };
